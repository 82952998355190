<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>我的质检报告</p>
      </div>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas" border>
        <el-table-column prop label="商品分类"></el-table-column>
        <el-table-column prop label="数量"></el-table-column>
        <el-table-column prop label="重量"></el-table-column>
        <el-table-column prop label="样品编码"></el-table-column>
        <el-table-column prop label="仓单编码"></el-table-column>
        <el-table-column prop label="质检报告"></el-table-column>
        <el-table-column prop label="第三方质检报告"></el-table-column>
        <el-table-column prop label="质检机构"></el-table-column>
        <el-table-column prop label="上传时间"></el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        name: null,
        warehouse: null
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    // this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_selectLtCommodity.param.page = this.current - 1;
      protocolFwd.param_selectLtCommodity.param.size = this.pageSize;
      protocolFwd.param_selectLtCommodity.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocolFwd.param_selectLtCommodity).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.tableList {
  margin-top: 20px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
